import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Home"
        keywords={[
          `albert`,
          `berk`,
          `toledo`,
          `polymath`,
          "software engineer",
          "artist",
          "filmmaker",
          "photographer",
        ]}
      />

      <article className="post-content page-template no-image">
        <Img
          style={{ maxWidth: 256, margin: "auto" }}
          fluid={data.profileImage.childImageSharp.fluid}
        ></Img>
        <hr></hr>
        <h2>I tell stories with code, images, words and more...</h2>
        <hr></hr>
        <div className="post-content-body">
          <p>
            I am a{" "}
            <a href="https://www.linkedin.com/in/toledoalbert/">
              software engineer
            </a>
            , a{" "}
            <a href="https://hamptonpirates.com/sports/sailing/roster/albert-toledo/2263">
              former sailor
            </a>{" "}
            and a proud <a href="https://afs.org/">AFSer.</a> I am an
            interdisciplinary creator who likes to blur the borders between arts
            and technology.
          </p>
          <p>
            Currently I live in{" "}
            <a href="https://youtu.be/McLgpck2i7A">New York City</a> and work at{" "}
            <a href="https://www.testco.de">Testcode</a>, where we are
            exerimenting with and building social consumer software.
          </p>
          <p>
            I like to use creativity as a way of studying human nature. I
            believe stories help us make sense of life and communicate the most
            complex aspects of human nature. I like to tell stories and develop
            technology that helps others do the same.
          </p>
          <p>
            I was born and raised in{" "}
            <a href="https://en.wikipedia.org/wiki/Istanbul">Istanbul</a> where
            I went to the{" "}
            <a href="https://en.wikipedia.org/wiki/St._George%27s_Austrian_High_School">
              Austrian High School
            </a>
            . I lived in{" "}
            <a href="https://en.wikipedia.org/wiki/Oviedo">Spain</a> and the{" "}
            <a href="https://en.wikipedia.org/wiki/Hampton_University">
              United States
            </a>
            , traveled more and learned 4 languages along the way. Right now,
            apart from my work at Testcode, I am writing a feature length screenplay.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profileImage: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
